import {URL_PROIP} from '@services/common/utils/settings'

const getproIp = async () => {
  return fetch(URL_PROIP)
  .then((response) => response.json())
  .then((data) => {
    return data
  })
  .catch((error) => {
    throw new Error('countryCode is undefined');
  })
}
export default getproIp