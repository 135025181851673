import { useState, useContext } from "react";
//Context
import GlobalContext from "@context/global/GlobalContext";
//Hooks
import useCancellablePromise from "@customHooks/common/useCancellablePromise";
import useModal from "@customHooks/common/modal/useModal";
//import {useAllInfo} from "@customHooks/common/account/useAllInfo"
import useLocalizedRoutes from "@customHooks/common/useLocalizedRoutes";
//Getters
import getReceivers from "@src/services/envios/receivers/getReceivers";
import addReceiver from "@src/services/envios/receivers/addReceiver";
import editReceiver from "@src/services/envios/receivers/editReceiver";
import removeReceiver from "@src/services/envios/receivers/removeReceiver";
import { addToStorage } from "@src/utils/storage";

export function useReceivers() {
  const { axiosInstance, setReceivers, setReceiver, setSearchProvinceId } =
    useContext(GlobalContext);
  const { handleModal } = useModal();
  const { cancellablePromise } = useCancellablePromise();
  const [loadingReceivers, setLoadingReceivers] = useState(false);
  const { navigate } = useLocalizedRoutes();

  const getAllReceivers = async () => {
    setLoadingReceivers(true);
    const data = {
      cuballamaId: null, //accountInfo.accountId,
      searchBy: {},
    };
    return cancellablePromise(getReceivers(data, axiosInstance))
      .then((response) => {
        if (response.canceled) return null;
        if (response.status === "ok") {
          setReceivers(response.formattedData);
        } else {
          if (response.useAlert) handleModal(response.alertData);
        }
        return response;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingReceivers(false);
      });
  };

  const addAReceiver = ({ data, callback }) => {
    setLoadingReceivers(true);
    cancellablePromise(addReceiver(data, axiosInstance))
      .then((response) => {
        if (!response.canceled) {
          if (response.status === "ok") {
            if (typeof callback === "function") {
              callback({ data: response.formattedData, status: "ok" });
            }
          } else {
            //Mostrar alerta
            if (response.useAlert) handleModal(response.alertData);
            //Hacer redirect
            //if(response.useRedirect) navigate(response.route || "HOME")
          }
          setLoadingReceivers(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const isDocumentError = (x) =>
    x.match(/\{receiver=\{document=\[(.*?)\]\}\}/)?.[1];

  const editAReceiver = ({ data, callback }) => {
    setLoadingReceivers(true);
    editReceiver(data, axiosInstance)
      .then((response) => {
        if (response.canceled) return;
        if (response.status === "ok") {
          if (typeof callback === "function") {
            callback({ status: "ok", ...response.formattedData });
          }
          return;
        }
        if (response.description) {
          let message = response.description;

          if (isDocumentError(response.description)) {
            message = isDocumentError(response.description);
          }

          const modalData = {
            type: "accept",
            data: {
              title: "Ha ocurrido un error",
              text: message,
              icon: "warning",
            },
          };
          handleModal(modalData);
          return;
        }
        //Mostrar alerta
        if (response.useAlert) handleModal(response.alertData);
        //Hacer redirect
        if (response.useRedirect) navigate(response.route || "HOME");
      })
      .catch((error) => {
        console.error(error);
        return null;
      })
      .finally(() => {
        setLoadingReceivers(false);
      });
  };

  const removeAReceiver = async ({ id }) => {
    setLoadingReceivers(true);
    return cancellablePromise(removeReceiver(id, axiosInstance))
      .then((response) => {
        if (response.canceled) return null;
        if (response.status !== "ok") {
          //Mostrar alerta
          if (response.useAlert) handleModal(response.alertData);
          //Hacer redirect
          if (response.useRedirect) navigate(response.route || "HOME");
        }
        return response;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingReceivers(false);
      });
  };

  //Persistimos los id's de provincia y receptor a la vez que asignar a sus estados
  const selectAndPersistReceiver = (data) => {
    if (!data || !data?.receiver_id) return;
    setSearchProvinceId(data.province);
    addToStorage("m-province", data.province);
    setReceiver(data);
    addToStorage("receiver", data.receiver_id);
  };

  return {
    loadingReceivers,
    setLoadingReceivers,
    getAllReceivers,
    addAReceiver,
    editAReceiver,
    removeAReceiver,
    setReceivers,
    setReceiver,
    selectAndPersistReceiver,
  };
}
