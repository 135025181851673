import React, { useContext } from "react";
//Context
import GlobalContext from "@context/global/GlobalContext";
//Hooks
import routes from "/routes/routes.json";
import {
  Link as LinkI18,
  Trans,
  useI18next,
} from "gatsby-plugin-react-i18next"; //, useTranslation
import { navigate as gatsbyNavigate } from "gatsby";
import { format } from "date-fns";
import ComponentLink from "@mui/material/Link";
import { getFromStorage, addToStorage } from "@utils/storage";

export default function useLocalizedRoutes() {
  const { authorized } = useContext(GlobalContext);
  const { t, language, defaultLanguage, originalPath, changeLanguage } =
    useI18next();

  const langToCurrency = { US: "USD", ES: "EUR", CA: "CAD" };
  const currencyToLang = { USD: "US", EUR: "ES", CAD: "CA" };
  const getLangCurrency = (lang) => {
    const curr = lang.split("-")[1]?.toUpperCase() ?? "US";
    return langToCurrency[curr] ?? "USD";
  };
  const localeCurrency = getLangCurrency(language);

  const getTermLocales = (term) => {
    return routes[term] || null;
  };

  const getTranslatedRoute = (route, lang) => {
    //Ruta ROUTE
    return routes.hasOwnProperty(route)
      ? routes[route][lang || language]
      : route;
  };

  const getFullTranslatedRoute = (route, lang = language) => {
    //Ruta /idioma?/ROUTE
    const newRoute = getTranslatedRoute(route, lang);
    return newRoute === route
      ? route
      : `${defaultLanguage !== lang ? `/${lang}` : ``}${newRoute}`;
  };

  const getRouterPath = (route, lang = language) => {
    //PARA las rutas privadas de REACH ROUTER
    return `${defaultLanguage !== lang ? lang : ""}${getTranslatedRoute(
      route,
      lang
    )}`;
  };

  const getFullUri = (route) => {
    let fullPath = "";
    if (typeof window !== "undefined") {
      fullPath = window.location.protocol + "//" + window.location.host;
    }
    fullPath += getFullTranslatedRoute(route);
    return fullPath;
  };
  const getFullUriWithPrefix = (route) => {
    let fullPath = "";
    if (typeof window !== "undefined") {
      fullPath = window.location.protocol + "//" + window.location.host;
    }
    return fullPath + __PATH_PREFIX__ + getFullTranslatedRoute(route);
  };

  const getRouteId = (path) => {
    let result = null;
    if (path) {
      const configDefaultLanguage = process.env.GATSBY_DEFAULT_LANGUAGE;
      const configLanguages = process.env.GATSBY_LANGUAGES.split(",").filter(
        (lang) => {
          return lang !== configDefaultLanguage;
        }
      );
      let languagePart = configDefaultLanguage;
      let joinIndex = 0;
      const pathParts = path.slice(1, -1).split("/");
      if (configLanguages.includes(pathParts[0])) {
        languagePart = pathParts[0];
        joinIndex = 1;
      }
      const joinPath = pathParts.slice(joinIndex).join("/");
      if (joinPath === "") {
        result = "HOME";
      } else {
        for (const item in routes) {
          if (routes[item][languagePart] === `/${joinPath}/`) {
            result = item;
            break;
          }
        }
      }
    } else {
      result = "HOME";
    }
    return result;
  };

  const changeLocale = ({
    lang,
    slugs = null,
    route = originalPath.substring(1, originalPath.length - 1),
  }) => {
    if (authorized && lang) {
      // IMPORTANTE cambiar si utilizamos el cambio de idioma en la cuenta
      addToStorage("accountLang", lang);
    }
    // IMPORTANTE cambiar si utilizamos el cambio de idioma en la cuenta
    const path = slugs ? slugs[lang] : getTranslatedRoute(route, lang);
    if (path !== route) {
      // IMPORTANTE cambiar si utilizamos el cambio de idioma en la cuenta
      changeLanguage(lang, path, { replace: false });
    }
  };

  /*const getCurrencyLang = (currency)=>{
    const currencies = routes["currency"]
    const values = Object.values(currencies)
    const keys = Object.keys(currencies)
    const newLang = keys[values.findIndex((value)=>{
      return currency === value
    })]
    return newLang
  }*/

  /*const getLangCurrency = (lang)=>{
    return getTranslatedRoute("currency", lang)
  }*/

  const navigate = (route, options = {}) => {
    gatsbyNavigate(getFullTranslatedRoute(route), options);
  };

  const externalNavigate = (url, target = "_self") => {
    window.open(url, target);
  };

  const initialNavigateToCurrency = ({ route, currency }) => {
    // let accountLang = getFromStorage("accountLang");
    const defaultLang = process.env.GATSBY_DEFAULT_LANGUAGE;
    // if (!accountLang) {
    //   accountLang = defaultLang;
    //   addToStorage("accountLang", accountLang);
    // }
    // const newLang = `${accountLang.substring(0, 2)}-${
    //   currencyToLang[currency]
    // }`;
    const newLang = `es-${currencyToLang[currency]}`;
    const newRoute = getTranslatedRoute(route, newLang);
    gatsbyNavigate(
      `${defaultLang !== newLang ? `/${newLang}` : ``}${newRoute}`,
      { replace: true }
    );
  };

  const navigateToCurrency = ({ route, currency = null }) => {
    let routeWithNoLocaleOrCurrency = route;
    //Evitamos que al usar una ruta que ya tiene local se encime con otra
    if (route.startsWith("es") || route.startsWith("en")) {
      let splitRoute = route.split("/");
      let joined = splitRoute.slice(1).join("/");
      routeWithNoLocaleOrCurrency = joined;
      if (!routeWithNoLocaleOrCurrency.startsWith("/")) {
        routeWithNoLocaleOrCurrency = "/" + routeWithNoLocaleOrCurrency;
      }
    }
    if (currency) {
      const defaultLang = process.env.GATSBY_DEFAULT_LANGUAGE;
      let accountLang = getFromStorage("accountLang");
      if (!accountLang) {
        accountLang = language;
        addToStorage("accountLang", accountLang);
      }
      const newLang = `${accountLang.substring(0, 2)}-${
        currencyToLang[currency]
      }`;
      const newRoute = getTranslatedRoute(routeWithNoLocaleOrCurrency, newLang);
      gatsbyNavigate(
        `${defaultLang !== newLang ? `/${newLang}` : ``}${newRoute}`,
        { replace: true }
      );
    } else {
      navigate(route);
    }
  };

  const getCentsNotation = () => {
    const currency = routes["currency"][language];
    return currency === "EUR" ? "," : ".";
  };

  const getLocalizationCurrency = () => {
    return getTranslatedRoute("currency"); //Valores de formato en routes
  };

  const formatNumber = (number) => {
    return Number(number).toLocaleString(getTranslatedRoute("localeFormat"), {
      style: "decimal",
    });
  };

  const formatCurrency = (
    number,
    currency = process.env.GATSBY_DEFAULT_CURRENCY
  ) => {
    if (number === null || isNaN(number)) return "";
    return Number(number).toLocaleString(getTranslatedRoute("localeFormat"), {
      style: "currency",
      currency: currency,
    });
  };

  const formatDate = (date, dateFormat = null) => {
    return format(date, dateFormat || getTranslatedRoute("dateFormat"));
  };

  const formatDateTime = (date, dateFormat = null) => {
    return format(date, dateFormat || getTranslatedRoute("dateFormat"));
  };

  const formatMillisecondsDate = (milliseconds, dateFormat = null) => {
    return formatDate(new Date(milliseconds, dateFormat));
  };

  const formatMillisecondsDateTime = (milliseconds, dateFormat = null) => {
    return formatDateTime(new Date(milliseconds, dateFormat));
  };

  const getDateFormat = () => {
    return getTranslatedRoute("dateFormat");
  };

  const addDaysToDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const substractDaysToDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  const Link = React.forwardRef(
    (
      {
        to,
        href = null,
        lang = null,
        children,
        onClick,
        full = false,
        component = null,
        ...rest
      },
      ref
    ) => {
      let link = getTranslatedRoute(to, lang);
      const TagName = component || ComponentLink;
      if (href) {
        link = href;
      } else if (full && typeof window !== "undefined") {
        link = window.location.protocol + "//" + window.location.host + link;
      }
      const handleClick = (e) => {
        /*if (language) {
        localStorage.setItem("gatsby-intl-language", language)
      }*/
        if (onClick) {
          onClick(e);
        }
      };
      return (
        <TagName
          ref={ref}
          component={LinkI18}
          {...rest}
          to={link}
          onClick={handleClick}
        >
          {children}
        </TagName>
      );
    }
  );

  const isOnTheLocation = (baseRoutes) => {
    let result = null;
    let location = null;

    if (typeof window !== "undefined") {
      location = window.location;
    }

    if (location && baseRoutes) {
      result = baseRoutes.some((baseRoute) =>
        new RegExp(`^/${baseRoute}/.*$`).test(location.pathname)
      );
    } else {
      result = false;
    }

    return result;
  };

  const LocalizeNumber = ({ number, ...rest }) => {
    return <React.Fragment {...rest}>{formatNumber(number)}</React.Fragment>;
  };

  const LocalizeCurrency = ({ number, currency = null, ...rest }) => {
    return (
      <React.Fragment {...rest}>
        {formatCurrency(number, currency)}
      </React.Fragment>
    );
  };

  const LocalizeDate = ({ date, dateFormat = null, ...rest }) => {
    return (
      <React.Fragment {...rest}>{formatDate(date, dateFormat)}</React.Fragment>
    );
  };

  const redirectRouteOrFunction = (backTo) => {
    switch (typeof backTo) {
      case "string":
        navigate(backTo);
        break;
      case "function":
        backTo();
        break;
      default:
        break;
    }
  };

  return {
    Link,
    Trans,
    language,
    defaultLanguage,
    langToCurrency,
    t,
    changeLocale,
    getRouterPath,
    getLocalizationCurrency,
    getTranslatedRoute,
    getFullTranslatedRoute,
    getFullUri,
    getRouteId,
    navigate,
    externalNavigate,
    initialNavigateToCurrency,
    navigateToCurrency,
    formatNumber,
    formatDate,
    formatDateTime,
    formatMillisecondsDate,
    formatMillisecondsDateTime,
    formatCurrency,
    getCentsNotation,
    getDateFormat,
    getTermLocales,
    addDaysToDate,
    substractDaysToDate,
    redirectRouteOrFunction,
    LocalizeNumber,
    LocalizeCurrency,
    LocalizeDate,
    isOnTheLocation,
    getFullUriWithPrefix,
    currencyToLang,
    localeCurrency,
    originalPath,
    changeLanguage,
  };
}
