import React, {useState} from 'react'
const ModalContext = React.createContext({})

export function ModalProvider ({children}) {
  const [modalContent, setModalContent] = useState(null)
  const [onEvent, setOnEvent] = useState(null)
  
  return <ModalContext.Provider value={{
    modalContent,
    setModalContent,
    onEvent,
    setOnEvent
  }}>
    {children}
  </ModalContext.Provider>
}
export default ModalContext