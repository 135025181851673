import {
  URL_SHIPPING_BASE,
  URL_GET_API_SENDER_UPDATE,
} from "@services/common/utils/settings";
import responseEvaluation, {
  convertResponseForEvaluation,
} from "@services/common/utils/serviceResponseEvaluation";

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data.data;
};

const updateSender = async (data, axiosInstance) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_GET_API_SENDER_UPDATE}`;

  // const bodyData = {
  //   sender_id: data.sender_id,
  //   name: "Amador Rivas Perez",
  //   phone: "+5353258888",
  //   town: "Habana",
  //   country: "Cuba",
  //   zip: "2344",
  //   reparto: "Buena Vista",
  //   address: "Bodega y Pto del Gas y EN donde juegan la bolita.",
  //   // document: "90000001111",
  //   // landLine: "+5372880000",
  //   state: "La Lisa",
  //   // email: "amador@hotmail.com",
  //   // favourite: true,
  //   // isDefault: false,
  //   // first_name: "test",
  //   // last_name: "test",
  // };

  return axiosInstance
    .post(apiURL, data)
    .then((response) => {
      const evaluation = responseEvaluation(
        convertResponseForEvaluation(response)
      );
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return error?.response?.data ?? null;
    });

  // //Se procesa la petición
  // const response = await ({
  //   axiosInstance: axiosInstance,
  //   method: "POST",
  //   url: apiURL,
  //   useToken: "TOKEN_API_ENVIOS",
  //   callData: { body: data, bodyEncode: "JSON" },
  // });
  //
  // //Evaluamos la respuesta del
  // const evaluation = responseEvaluation(response);
  // if (evaluation.status === "ok") {
  //   evaluation.formattedData = formatApiResponse(response.data);
  // }
  // return evaluation;
};
export default updateSender;
