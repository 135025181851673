import { useContext } from "react"
//Context
import ModalContext from "@context/modal/ModalContext";

export default function useModal() {
  const { modalContent, setModalContent } = useContext(ModalContext)
  const handleModal = (content = null) => {
    setModalContent(content)
  }
  return { handleModal, modalContent, setModalContent};
};