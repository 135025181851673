import React, { useEffect } from "react";
import { Script } from "gatsby";
//Hooks
import { useProIp } from "@customHooks/common/thirdParties/useProIp";
import { useAllInfo } from "@customHooks/common/account/useAllInfo";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useLocalizedRoutes from "@customHooks/common/useLocalizedRoutes";

//SVGs
import cllChatA from "@static/images/svg/twilio/cbll-chat.svg";
import cllChatB from "@static/images/svg/twilio/cbll-chat-b.svg";

const TwilioChat = () => {
  const { ipData } = useProIp();
  const { accountInfo } = useAllInfo();
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const { getRouteId } = useLocalizedRoutes();

  const getLanguage = () => {
    const languages = process.env.GATSBY_LANGUAGES.split(",");
    const defaultLanguage = process.env.GATSBY_DEFAULT_LANGUAGE;
    const identifier = window.location.pathname.substring(1, 6);
    const selectedLanguage = languages.includes(identifier)
      ? identifier
      : defaultLanguage;
    return selectedLanguage.substring(0, 2);
  };

  const checkVisibility = () => {
    const routeId = getRouteId(window.location.pathname);
    const visibility =
      downMd && (routeId !== "HOME" || routeId === null) ? "none" : "inherit";
    const item = document.getElementById("twilio-customer-frame");
    if (item) item.style.setProperty("display", visibility);
  };
  const onPathDeviceChange = () => {
    checkVisibility();
  };
  //eslint-disable-next-line
  useEffect(onPathDeviceChange, [downMd, window.location.pathname]);

  //Data
  const lang = getLanguage();
  const maxChars = 600;
  const brandColor1 = "#1976d2";
  const brandColor2 = "#233659";
  const brandTextColor = "#ffffff";
  const personalizedColors = {
    darkBlueBackground: "#304582",
    whiteText: "#FFFFFF",
    entryPointBackground: "#1E4ACC", //"#3C425C",
    lighterBackground: "#ecedf1",
    primaryButtonBackground: "#1E4ACC",
    primaryButtonColor: "#FFFFFF",
    secondaryButtonBackground: "#6e7180",
    secondaryButtonColor: "#FFFFFF",
  };

  const brandMessageBubbleColors = (bgColor) => ({
    Bubble: {
      background: bgColor,
      color: brandTextColor,
    },
    Avatar: {
      //background: bgColor,
      color: brandTextColor,
    },
    Header: {
      color: brandTextColor,
    },
  });

  const brandedColors = {
    Chat: {
      MessageListItem: {
        FromOthers: brandMessageBubbleColors(brandColor2),
        FromMe: brandMessageBubbleColors(brandColor1),
      },
      MessageInput: {
        Container: {
          Button: {
            background: brandColor1,
            color: brandTextColor,
          },
        },
      },
      MessageCanvasTray: {
        Container: {
          background: personalizedColors.darkBlueBackground,
          color: personalizedColors.whiteText,
        },
      },
    },
    MainHeader: {
      Container: {
        background: personalizedColors.darkBlueBackground,
        color: personalizedColors.whiteText,
      },
      Logo: {
        fill: brandTextColor,
      },
    },
    EntryPoint: {
      Container: {
        background: personalizedColors.entryPointBackground,
        color: personalizedColors.whiteText,
        right: "10px",
      },
    },
    /*MainContainer: {
          top: 0,
          bottom: "76px",
          left: 0,
          right: 0,
          width: "100%",
          height: "auto",
          maxHeight: "100%",
          margin: 0,
          padding: 0,
      }*/
  };

  const langData = {
    es: {
      client: "Cliente",
      mainTitle: "Bienvenido a Cuballama",
      description:
        "Bienvenido al chat de Atención al Cliente de Cuballama. Antes de comenzar, por favor, completa este formulario.",
      labelText: "Nombre",
      placeholderText: "Indica tu nombre",
      labelEmailChat: "Correo electrónico",
      placeholderEmailChat: "Indica tu email",
      labelPhoneChat: "Número de celular",
      placeholderPhoneChat: "Con código país (1 para USA y Canadá)",
      labelHelpChat: "Tema de tu consulta",
      placeholderHelpChat: "Indica el tema más apropiado para ayudarte",
      optionsRegistroLabel: "Registro",
      optionsPagosLabel: "Pagos",
      optionsLlamadasLabel: "Llamadas",
      optionsGeneralLabel: "Otros",
      labelDescribeIssueChat: "Describe tu duda",
      placeholderDescribeIssueChat:
        "Explica, tan breve como puedas, tu consulta",
      submitLabel: "Comenzar",
      entryPointTagline: "Chat Cuballama",
      messageCanvasTrayContent: `<h6>¡Gracias por contactar con nosotros!</h6><p>Si tienes más preguntas, por favor contacta con nosotros de nuevo.</p>`,
      messageCanvasTrayButton: "INICIAR NUEVO CHAT",
      invalidPreEngagementMessage:
        "No hemos recibido los formularios previos y son imprescindibles para iniciar el chat. Por favor, configúralo ahora en la configuración.",
      invalidPreEngagementButton: "Saber más",
      predefinedChatMessageAuthorName: "Cuballama Bot",
      predefinedChatMessageBody: "¡Hola! ¿Cómo puedo ayudarte hoy?",
      inputPlaceHolder: "Escribe un mensaje",
      typingIndicator: "El agente está escribiendo ... ",
      read: "Leer",
      messageSendingDisabled: "Se ha deshabilitado el envío de mensajes",
      today: "HOY",
      yesterday: "AYER",
      welcomeMessage: "Bienvenido a Atención al Cliente",
      save: "Guardar",
      reset: "Reiniciar",
      MessageCharacterCountReached: `Máximo ${maxChars} caracteres`,
      sendMessageTooltip: "Enviar mensaje",
      fieldValidationRequiredField: "Campo requerido",
      fieldValidationInvalidEmail:
        "Por favor, indica un correo electrónico válido",
      optionsEviosOption1Label: "¿Cómo funciona el servicio de Envíos?",
      optionsEviosOption2Label: "¿Qué artículos puedo enviar? ¿Cuánto demora?",
      optionsEviosOption3Label:
        "Saber sobre mi orden: estatus, pagos en Cuba, etc.",
      optionsEviosOption4Label:
        "¿Que modalidades de envíos ofrecen? (Aire, Mar, Amazon, etc)",
      optionsEviosOption5Label: "Otras preguntas",
    },
    en: {
      client: "Client",
      mainTitle: "Welcome to Cuballama",
      description:
        "Welcome to Cuballama’s Customer Service chat. Before starting, please complete this form.",
      labelText: "Name",
      placeholderText: "Inout your name",
      labelEmailChat: "e-mail",
      placeholderEmailChat: "Input your e-mail",
      labelPhoneChat: "Phone number",
      placeholderPhoneChat: "Number with country code (1 for USA and Canada)",
      labelHelpChat: "Subject of your query",
      placeholderHelpChat: "Indicate the most appropriate topic to help you.",
      optionsRegistroLabel: "Registration",
      optionsPagosLabel: "Payments",
      optionsLlamadasLabel: "Calls",
      optionsGeneralLabel: "Other",
      labelDescribeIssueChat: "Describe your question",
      placeholderDescribeIssueChat: "Briefly describe the selected topic",
      submitLabel: "Start",
      entryPointTagline: "Cuballama chat",
      messageCanvasTrayContent: `<h6>Thank you for contacting us!</h6><p>If you have any further questions, please contact us again.</p>`,
      messageCanvasTrayButton: "START NEW CHAT",
      invalidPreEngagementMessage:
        "We have not received the previous forms and they are essential to start the chat. Please configure it now in the settings.",
      invalidPreEngagementButton: "Know more",
      predefinedChatMessageAuthorName: "Cuballama Bot",
      predefinedChatMessageBody: "Hello! How can I help you today?",
      inputPlaceHolder: "Write a message",
      typingIndicator: "Agent is typing ... ",
      read: "Read",
      messageSendingDisabled: "Sending messages has been disabled",
      today: "TODAY",
      yesterday: "YESTERDAY",
      welcomeMessage: "Welcome to Customer Service",
      save: "Save",
      reset: "Restart",
      MessageCharacterCountReached: `${maxChars} characters max`,
      sendMessageTooltip: "Send message",
      fieldValidationRequiredField: "Required field",
      fieldValidationInvalidEmail: "Please enter a valid email",
      optionsEviosOption1Label: "How does the Shipping service work?",
      optionsEviosOption2Label: "What items can I ship? How long does it take?",
      optionsEviosOption3Label:
        "Know about my order: status, payments in Cuba, etc.",
      optionsEviosOption4Label:
        "What shipping methods do you offer? (Air, Sea, Amazon, etc)",
      optionsEviosOption5Label: "Other questions",
    },
  };

  const getConfig = () => {
    return {
      accountSid: process.env.GATSBY_ASID,
      flexFlowSid: process.env.GATSBY_FSID,
      colorTheme: {
        overrides: brandedColors,
      },
      fileAttachment: {
        enabled: true,
        maxFileSize: 26214400,
        acceptedExtensions: ["png", "txt", "pdf", "jpeg", "jpg", "gif"],
      },
      sdkOptions: {
        chat: {},
        voice: {},
      },
      context: {
        friendlyName: accountInfo?.accountId || "Anonymous",
        locationOrigin: ipData && ipData.query ? ipData.query : "-",
        source: "Envios - WEB",
      },
      componentProps: {
        MainHeader: {
          titleText: langData[lang].mainTitle,
          imageUrl: cllChatA,
        },
        MessageInput: {
          returnKeySendsMessage: true,
        },
        MessagingCanvas: {
          charLimit: maxChars,
          showReadStatus: true,
          showTypingIndicator: true,
          predefinedMessage: false,
          showWelcomeMessage: false,
        },
        EntryPoint: {
          iconExpanded: "ArrowDown",
        },
      },
      startEngagementOnInit: false,
      preEngagementConfig: {
        //este sería el formulario inicial donde se recogen las primeras inquietudes del cliente
        description: langData[lang].description,
        fields: [
          {
            //tipo entrada texto
            label: langData[lang].labelText,
            type: "InputItem",
            attributes: {
              name: "friendlyName",
              placeholder: langData[lang].placeholderText,
              type: "text",
              required: true,
              value: "",
            },
          },
          {
            label: langData[lang].labelEmailChat,
            type: "InputItem",
            attributes: {
              name: "friendlyEmail",
              placeholder: langData[lang].placeholderEmailChat,
              type: "email",
              required: true,
              value: "",
            },
          },
          {
            label: langData[lang].labelPhoneChat,
            type: "InputItem",
            attributes: {
              name: "friendlyPhone",
              placeholder: langData[lang].placeholderPhoneChat,
              type: "number",
              required: true,
              value: "",
            },
          },
          {
            // tipo selector
            label: langData[lang].labelHelpChat,
            type: "SelectItem",
            attributes: {
              name: "friendlySelect",
              placeholder: langData[lang].placeholderHelpChat,
              type: "text",
              required: true,
            },
            options: [
              {
                value: "¿Cómo funciona?",
                label: langData[lang].optionsEviosOption1Label,
                selected: false,
              },
              {
                value: "¿Qué puedo enviar?",
                label: langData[lang].optionsEviosOption2Label,
                selected: false,
              },
              {
                value: "Saber sobre mi orden",
                label: langData[lang].optionsEviosOption3Label,
                selected: false,
              },
              {
                value: "Modalidades de envío",
                label: langData[lang].optionsEviosOption4Label,
                selected: false,
              },
              {
                value: "Otras preguntas",
                label: langData[lang].optionsEviosOption5Label,
                selected: false,
              },
            ],
          },
          {
            // tipo text area, soporta mas texto, aqui se envia la consulta inicial
            label: langData[lang].labelDescribeIssueChat,
            type: "TextareaItem",
            attributes: {
              name: "question",
              type: "text",
              placeholder: langData[lang].placeholderDescribeIssueChat,
              required: true,
              rows: 5,
            },
          },
        ],
        submitLabel: langData[lang].submitLabel,
      },
    };
  };
  //calback
  const initCallback = () => {
    try {
      const flexChat = window.Twilio.FlexWebChat;
      flexChat.createWebChat(getConfig()).then((webchat) => {
        const { manager } = webchat;
        flexChat.Actions.on("afterStartEngagement", (payload) => {
          const { question } = payload.formData;
          if (!question) return;
          const { channelSid } = manager?.store?.getState?.()?.flex?.session;
          manager.chatClient
            .getChannelBySid(channelSid)
            .then((channel) => channel.sendMessage(question));
        });
        manager.strings.WelcomeMessage = langData[lang].welcomeMessage;
        manager.strings.EntryPointTagline = langData[lang].entryPointTagline;
        //manager.strings.PredefinedChatMessageBody = langData[lang].predefinedChatMessageBody
        manager.strings.InputPlaceHolder = langData[lang].inputPlaceHolder;
        manager.strings.SendMessageTooltip = langData[lang].sendMessageTooltip;
        manager.strings.FieldValidationRequiredField =
          langData[lang].fieldValidationRequiredField;
        manager.strings.FieldValidationInvalidEmail =
          langData[lang].fieldValidationInvalidEmail;
        manager.strings.Today = langData[lang].today;
        manager.strings.Yesterday = langData[lang].yesterday;
        manager.strings.Read = langData[lang].read;
        manager.strings.TypingIndicator = langData[lang].typingIndicator;
        manager.strings.MessageCanvasTrayButton =
          langData[lang].messageCanvasTrayButton;
        manager.strings.MessageCanvasTrayContent =
          langData[lang].messageCanvasTrayContent;
        manager.strings.Save = langData[lang].save;
        manager.strings.Reset = langData[lang].reset;
        //manager.strings.PredefinedChatMessageAuthorName = langData[lang].predefinedChatMessageAuthorName
        manager.strings.MessageCharacterCountStatus =
          "{{currentCharCount}} / {{maxCharCount}}";
        manager.strings.MessageCharacterCountReached =
          langData[lang].MessageCharacterCountReached +
          " - {{currentCharCount}} / {{maxCharCount}}";
        webchat.init();

        // Sobrescribir el comportamiento del botón X para finalizar el chat en lugar de minimizarlo
        const closeButton = document.querySelector(
          ".Twilio-Icon.Twilio-Icon-Close"
        );
        if (closeButton) {
          closeButton.addEventListener("click", async (e) => {
            e.preventDefault(); // Evitar el comportamiento predeterminado (minimizar)

            // Obtén el canal actual del chat
            const channelSid =
              manager?.store?.getState?.()?.flex?.session?.channelSid;

            if (channelSid) {
              try {
                const channel = await manager.chatClient.getChannelBySid(
                  channelSid
                );

                // Finaliza la conversación cerrando el canal
                await channel.leave(); // Abandonar el canal
                await channel.delete(); // Borrar el canal si es necesario
              } catch (error) {
                console.error("Error al cerrar el canal de chat:", error);
              }
            }
          });
        }

        checkVisibility();
      });
      flexChat.MessageListItem.defaultProps.avatarUrl = cllChatB;
      flexChat.MessagingCanvas.defaultProps.predefinedMessage = false;

      /////////////////////////////////////////
      //Twilio.FlexWebChat.Actions.invokeAction("ToggleChatVisibility")
      //flexChat.Actions.invokeAction("MinimizeChat")
    } catch (error) {
      console.error("Couldn't init Twilio chat");
    }
  };

  return (
    <Script
      src="https://assets.flex.twilio.com/releases/flex-webchat-ui/2.9.1/twilio-flex-webchat.min.js"
      onLoad={() => initCallback()}
      strategy="post-hydrate"
    />
  );
};

export default TwilioChat;
